import { forEach, isObject, isArray, isEmpty, filter, padStart, includes, get } from 'lodash';
import { session } from '@elliemae/encw-commons';
// import { paths } from '@elliemae/encw-loan-parser';
class Utils {
  /**
   * Used to flatten the angular-formly model object to remove the unncessary parent objects and return the left objects
   * @param x
   * @param result
   * @param prefix
   * @returns {*}
   */
  flattenObjectDeep(x, result, prefix) {
    if (!isObject(x)) {
      result[prefix] = x;
    } else {
      forEach(x, (v, k) => {
        this.flattenObjectDeep(v, result, k);
      });
    }
    return result;
  }

  isWindows() {
    return window.navigator.userAgent.toLowerCase().indexOf('win') > -1;
  }

  splitCapitalWords(mess) {
    return mess.split(/(?=[A-Z])/);
  }

  /**
   * Renames the keys in a deeply nested object - e.g., converts all keys in loan obj to StartCase
   * @param obj
   * @returns {object} a transformed object with the keys replaced
   * e.g., if obj = {key1: {key2: 'test' }}
   * This function returns => {Key1: {Key2: 'test'}}
   */
  renameKeysToPascalCase(obj, skipFields) {
    for (let key in obj) {
      let numVal = Number(key);
      if (!isNaN(numVal)) {
        key = numVal;
      }
      let value = obj[key];

      if (key && key.charAt && !includes(skipFields, key)) {
        let upperKey = key.charAt(0).toUpperCase() + key.slice(1);
        obj[upperKey] = obj[key];
        delete obj[key];
      }
      if (typeof value == 'object') {
        this.renameKeysToPascalCase(value, skipFields);
      }
    }
  }

  /**
   * Renames the keys in a deeply nested object - e.g., converts all keys in loan obj to camelCase
   * @param obj
   * @returns {object} a transformed object with the keys replaced
   * e.g., if obj = {Key1: {Key2: 'test' }}
   * This function returns => {key1: {key2: 'test'}}
   */
  renameKeysToCamelCase(obj, skipFields) {
    for (let key in obj) {
      let numVal = Number(key);
      key = isNaN(numVal) ? key : numVal;
      let value = obj[key];

      if (key && key.charAt && !includes(skipFields, key)) {
        let firstChar = key.charAt(0);
        if (firstChar != firstChar.toLowerCase()) {
          let lowerKey = key.charAt(0).toLowerCase() + key.slice(1);
          obj[lowerKey] = obj[key];
          delete obj[key];
        }
      }
      if (isArray(value) || isObject(value)) {
        this.renameKeysToCamelCase(value, skipFields);
      }
    }
  }

  /**
   * Renames the keys in a deeply nested object with the given keyMap lookup
   * @param obj
   * @param keyMap
   * @returns {object} a transformed object with the keys replaced
   * e.g., if obj = {key1: {key2: [ {'id': 1, 'text': 'hello'}, {'id': 2, 'text': 'world'} ] }} & keyMap is {'id': 'uid', 'text':'name'}
   * This function returns => {key1: {key2: [ {'uid': 1, 'name': 'hello'}, {'uid': 2, 'name': 'world'} ] }}
   */
  renameKeysDeep(obj, keyMap) {
    return forEach(obj, (value, key) => {
      if (keyMap[key]) {
        obj[keyMap[key]] = obj[key];
        delete obj[key];
      }
      if (isArray(value) || isObject(value)) {
        return this.renameKeysDeep(value, keyMap);
      }
    });
  }

  /**
   * Flattens/Compacts the individual key/values pairs from the array of objects to a single object
   * @param Array
   * @private
   * e.g., if arr = [{Key: 'RandomKey1', Value: 'Random Value 1'}, {Key: 'RandomKey2', Value: true}, {Key: 'RandomKey3', Value: 100}]
   * returns => { RandomKey1: 'Random Value 1', RandomKey2: true, RandomKey3: 100 }
   * */
  flattenKeyValuePairs(arr) {
    let comp = {};
    if (isArray(arr)) {
      // the json response can be both an array or an object
      forEach(arr, item => {
        comp[item.Key] = item.Value;
      });
    } else if (isObject(arr)) {
      comp[arr.Key] = arr.Value;
    }
    return comp;
  }

  // Convert "mm/dd/yyyy" to "/Date(123213123)/"
  convertDatetoEncompassString(value) {
    let tempValue = Date.parse(value);
    tempValue = isNaN(tempValue) ? '0' : tempValue.toString();
    return '/Date(' + tempValue + ')/';
  }

  // Convert "/Date(123213123)/" to "mm/dd/yyyy"
  parseEncompassDateString(fieldValue = '') {
    const inputDate = fieldValue;
    if (fieldValue) {
      if (fieldValue === '/Date(0)/') {
        fieldValue = '';
      } else {
        let d;
        /* To fix the invalid date issue when the date value is /Date(1504422000000-0700)/ vs "/Date(1471219200000)/"*/
        if (fieldValue.indexOf('-') !== -1) {
          d = new Date(Number(fieldValue.substring(6, fieldValue.length - 7)));
        } else {
          d = new Date(Number(fieldValue.substring(6, fieldValue.length - 2)));
        }

        if (d !== 'Invalid Date') {
          fieldValue =
            padStart(d.getUTCMonth() + 1, 2, '0') + '/' + padStart(d.getUTCDate(), 2, '0') + '/' + d.getUTCFullYear();
          if (fieldValue.indexOf('NaN') > -1) {
            const dateObj = new Date(inputDate);
            const year = dateObj.getFullYear();
            let month = dateObj.getMonth() + 1;
            let day = dateObj.getDate();

            if (day < 10) {
              day = '0' + day;
            }
            if (month < 10) {
              month = '0' + month;
            }
            fieldValue = month + '/' + day + '/' + year;
          }
        }
      }
    }
    return fieldValue || '';
  }

  getCurrentUTCDateTime() {
    let today = new Date();
    // UTC standard time format as expected by EBS E.g. "2016-03-02T00:43:00"
    return (
      today.getUTCFullYear() +
      '-' +
      ('0' + (today.getUTCMonth() + 1)).slice(-2) +
      '-' +
      ('0' + today.getUTCDate()).slice(-2) +
      'T' +
      ('0' + today.getUTCHours()).slice(-2) +
      ':' +
      ('0' + today.getUTCMinutes()).slice(-2) +
      ':' +
      ('0' + today.getUTCSeconds()).slice(-2)
    );
  }

  getCurrentFormattedDateTime() {
    let now = new Date();
    let dd = now.getDate();
    let mm = now.getMonth() + 1;
    let hh = now.getHours();
    let mi = now.getMinutes();
    let ampm = 'AM';
    if (hh > 12) {
      ampm = 'PM';
      hh = hh - 12;
    } else if (hh < 10) {
      hh = '0' + hh;
    }
    return (
      (mm >= 10 ? mm : '0' + mm) +
      '/' +
      (dd >= 10 ? dd : '0' + dd) +
      '/' +
      now.getFullYear() +
      ' ' +
      hh +
      ':' +
      (mi >= 10 ? mi : '0' + mi) +
      ' ' +
      ampm
    );
  }

  convertMonthToYear(month) {
    return month / 12;
  }

  getCurrentFormattedDate() {
    let now = new Date();
    let dd = now.getDate();
    let mm = now.getMonth() + 1;
    return (mm >= 10 ? mm : '0' + mm) + '/' + (dd >= 10 ? dd : '0' + dd) + '/' + now.getFullYear();
  }

  generateToken() {
    return Math.floor(new Date().setDate(new Date().getDate() + 1) / 1000);
  }

  getModifiedFormlyFormFields(form, allFields = false) {
    let modfiedFormFields = {};
    if (form !== void 0 && form !== null && (form.$dirty || allFields)) {
      forEach(form, function(value, key) {
        if (key.indexOf('formly_') !== -1 || key.indexOf('form_') !== -1) {
          forEach(value, function(v, k) {
            if (
              v !== void 0 &&
              v !== null &&
              isObject(v) &&
              v.hasOwnProperty('$modelValue') &&
              (v.$dirty || allFields)
            ) {
              modfiedFormFields[k] = v['$modelValue'];
            }
          });
        }
      });
    }
    // this.$log.log('modified fields: ', modfiedFormFields);
    return modfiedFormFields;
  }

  // Compact object by removing all empty objects
  // var o = { 'Borrower': 'Test', 'Apps': [{}, {'id':1, 'name': 'test' }, {}, {'id': 3, 'name': 'test3'}], 'Prop': {}, 'Rate': 10 }
  // Returns -> // { 'Borrower': 'Test', 'Apps': [{'id':1, 'name': 'test' }, {'id': 3, 'name': 'test3'}], 'Rate': 10 }
  compactObject(o) {
    forEach(o, (v, k) => {
      if (isArray(v)) {
        if (v.length < 1) {
          delete o[k];
        } else {
          o[k] = filter(v, item => {
            return !isEmpty(item);
          });
          o[k].length < 1 ? delete o[k] : this.compactObject(o[k]);
        }
      } else if (isObject(v)) {
        isEmpty(v) ? delete o[k] : this.compactObject(v);
      }
    });
    return o;
  }

  generateComponentId(componentName) {
    return (
      (componentName || '') +
      this.s4() +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      this.s4() +
      this.s4()
    );
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  /**
   * dateDiff
   *
   * @param {string} fromdate (mm/dd/yyyy) OR microtime
   * @param {boolean} formateType
   *                   TRUE => date string as per service provided EX: '/Date(12255522554585252)'/
   *                   FALSE => date string in mm/dd/yyyy EX: '08/29/2016';
   *                   Default Value => TRUE
   * @param {string} todate (mm/dd/yyyy) OR microtime
   *                 this is optional parameter
   *                 if you dont pass it it will be consider as today's date
   * @returns Object { y: , m: d: }
   */
  dateDiff(fromdate, formateType = true, todate = undefined) {
    let todayDateObject = new Date();
    if (formateType === false) {
      if (todate !== undefined) {
        todayDateObject = new Date(todate);
      }
      fromdate = new Date(fromdate);
    } else {
      // true
      if (todate !== undefined) {
        if (todate.indexOf('/Date(') !== -1) {
          todate = todate.replace('/Date(', '').replace(')/', '');
          todayDateObject = new Date(parseInt(todate));
        } else {
          todayDateObject = new Date(todate);
        }
      }
      if (fromdate.indexOf('/Date(') !== -1) {
        fromdate = fromdate.replace('/Date(', '').replace(')/', '');
        fromdate = new Date(parseInt(fromdate));
      } else {
        fromdate = new Date(fromdate);
      }
    }

    var utcFrom = Date.UTC(fromdate.getFullYear(), fromdate.getMonth(), fromdate.getDate());
    var utcTo = Date.UTC(todayDateObject.getFullYear(), todayDateObject.getMonth(), todayDateObject.getDate());

    if (utcFrom == 0) {
      return {
        y: undefined,
        m: undefined
      };
    }
    // updated to return days diff as 0 if todate == fromdate
    if (utcFrom > utcTo) {
      return {
        y: 0,
        m: 0
      };
    } else {
      var diff = new Date(utcTo - utcFrom);
      return {
        y: diff.getFullYear() - 1970,
        m: diff.getMonth(),
        d: diff.getDate() - 1
      };
    }
  }

  // return a Date object, or empty string
  // changes /Date/ type to date obj
  parseDateValue(input) {
    var utc = '';
    if (input) {
      var match = input.match(/Date\((-)?(\d+)(-\d{4})?\)/);
      if (match && match[2] !== '0') {
        var value = new Date(Number(match[2]));
        // convert the date into UTC format
        // timestamp with a leading '-' means before 1970
        var timestamp = match[1] ? -value.getTime() : value.getTime();
        utc = new Date(timestamp + value.getTimezoneOffset() * 60000);
      }
    }
    return utc || (input && new Date(input));
  }

  // changes /date/ to mm/dd/yyyy
  dateParser(input) {
    let value = '';
    let d = this.parseDateValue(input);
    if (d && d !== 'Invalid Date') {
      value = padStart(d.getUTCMonth() + 1, 2, '0') + '/' + padStart(d.getUTCDate(), 2, '0') + '/' + d.getUTCFullYear();
    }
    return value;
  }

  convertJsonToPascal(res) {
    let newObj, originalKey, newKey, value;
    if (isArray(res)) {
      newObj = [];
      forEach(res, (v, originalKey) => {
        value = res[originalKey];
        if (isObject(value)) {
          value = this.convertJsonToPascal(value);
        }
        newObj.push(value);
      });
    } else {
      newObj = {};
      forEach(res, (v, originalKey) => {
        if (res.hasOwnProperty(originalKey)) {
          newKey = (originalKey.charAt(0).toUpperCase() + originalKey.slice(1) || originalKey).toString();
          value = res[originalKey];
          if ((value !== null && isObject(value)) || isArray(value)) {
            value = this.convertJsonToPascal(value);
          }
          newObj[newKey] = value;
        }
      });
    }
    return newObj;
  }

  // Only validates dates in the format  "2017-06-13T00:00:00Z"
  // If we start supporting other formats, this will need re-writing
  isDate(val) {
    if (val && typeof val === 'string') {
      let matchEncompassIsoDate = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/;
      if (val.match(matchEncompassIsoDate)) {
        return true;
      }
    }
    return false;
  }

  getFormattedDateTime(date) {
    date = new Date(date);
    if (!isNaN(date)) {
      let dd = date.getDate();
      let mm = date.getMonth() + 1;
      let hh = date.getHours();
      let mi = date.getMinutes();
      let ss = date.getUTCSeconds();
      let ampm = 'AM';
      if (hh > 12) {
        ampm = 'PM';
        hh = hh - 12;
        if (hh < 10) {
          hh = '0' + hh;
        }
      } else if (hh < 10) {
        hh = '0' + hh;
      }
      return (
        (mm >= 10 ? mm : '0' + mm) +
        '/' +
        (dd >= 10 ? dd : '0' + dd) +
        '/' +
        date.getFullYear() +
        ' ' +
        hh +
        ':' +
        (mi >= 10 ? mi : '0' + mi) +
        ':' +
        (ss >= 10 ? ss : '0' + ss) +
        ' ' +
        ampm
      );
    } else {
      return date;
    }
  }

  /**
   * Method to add parameters to url string
   * @param {string} url
   * @param {array} of {objects}. The {objects} are key-value pairs for the parameter name and parameter value.
   * @returns {string} representing the url with added parameters
   * @example
   * // returns myUrl.com?a=1&b=2
   *  addParamsToUrl('myUrl.com', [{a: 1}, {b: 2}]);
   */
  addParamsToUrl(url, params) {
    let isFirstParam = true;

    Array.isArray(params) &&
      params.forEach((el, index) => {
        let key = Object.keys(el);
        let value = key ? el[key] : undefined;

        if (value && isFirstParam) {
          url += `?${key}=${value}`;
          isFirstParam = false;
        } else if (value && !isFirstParam) {
          url += `&${key}=${value}`;
        }
      });

    return url;
  }
  addPathToUrl(baseUrl = '', pathArray = []) {
    const filteredPathArray = pathArray.filter(el => !!el);
    return !!filteredPathArray.length ? `${baseUrl}/${pathArray.filter(el => !!el).join('/')}` : baseUrl;
  }

  /**
   * Method to format phone numbers to xxx-xxx-xxxx
   * @param {string} phoneNumber
   * @param {string} formatString. Use $1 for the area code, $2 for the first three digits, and $2 for the last four digits. Defaults to '$1-$2-$3.
   * @returns {string} representing the formatted phone number
   */
  formatPhoneNumber(phoneNumber, formatString = '$1-$2-$3') {
    let regex = /\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/g;
    return typeof phoneNumber === 'string' ? phoneNumber.replace(regex, formatString) : phoneNumber;
  }

  /**
   * Method to bound a number between two numbers
   * @param {number} lowerBound
   * @param {number} upperBound
   * @param {number} value
   * @returns {number}
   */
  boundToRange(lowerBound, upperBound, value) {
    return Math.min(Math.max(value, lowerBound), upperBound);
  }

  /**
   * Method to calculate outer width (margins included) of a dom element
   * @param {object} el Dom element
   * @returns {number}
   */
  outerWidth(el) {
    var width = el.offsetWidth;
    var style = getComputedStyle(el);

    width += parseInt(style.marginLeft) + parseInt(style.marginRight);
    return width;
  }

  /**
   * Method for determining if an item in a group is checked.
   * @param {array} of DOM elements
   * @returns {boolean}
   */
  isItemInGroupChecked(items) {
    let isChecked = false;
    let i = 0;

    while (i < items.length && !isChecked) {
      isChecked = items[i].checked;
      ++i;
    }

    return isChecked;
  }

  /**
   * Method for case insensitive string match
   * @param string1
   * @param string2
   * @returns {boolean}
   */
  equalsIgnoreCase(string1, string2) {
    return string1.toUpperCase() == string2.toUpperCase();
  }

  /**
   * Method for extracting all query parameters from a url
   * @param {string} url
   * @returns {object} representing each query parameter
   */
  getUrlQueryParams(url = '') {
    let paramsArray = url.match(/(\?|\&)([^=]+)\=([^&]+)/g) || [];
    let paramsObj = {};
    let param;

    for (let i = 0; i < paramsArray.length; ++i) {
      param = paramsArray[i].substring(1).split('=');
      paramsObj[param[0]] = param[1];
    }
    return paramsObj;
  }

  /**
   * Method for determining if value is empty.
   * @param {*} value
   * @returns {boolean}
   */
  isEmpty(value) {
    return value == null || value === undefined || value === '';
  }

  /**
   * Precision rouding
   * @param {number} number
   * @param {number} representing the number of decimal places.
   * @returns {number}
   */
  precisionRound(number, precision) {
    let factor = Math.pow(10, precision);
    number = Math.round(number * factor) / factor;
    return Number.isInteger(number) ? number.toFixed(3) : number;
  }
  /**
   * Returns html for a divider.
   * @param {string} The divider. Defaults to '/'
   * @returns {string}
   *
   */
  getDividerHtml(divider = '/') {
    return `<span class="divider">${divider}</span>`;
  }
  /**
   * Android device detection
   * @param {string} User agent
   * @returns {boolean}
   */
  isAndroid(userAgent) {
    return /Android/.test(userAgent);
  }

  isSafari() {
    if (navigator) {
      let userAgent = (typeof navigator !== 'undefined' && navigator.userAgent) || '';
      let isSafari = /Safari\//.test(userAgent) && !/(Chrome\/|Android\s)/.test(userAgent);
      return isSafari;
    } else {
      return false;
    }
  }

  stringToNumber(strNumber) {
    if (strNumber) {
      return Number(strNumber.toString().replace(/,/g, '') || 0);
    }
    return 0;
  }

  showPurchasePrice(loanPurpose) {
    return (
      loanPurpose === 'ConstructionOnly' ||
      loanPurpose === 'ConstructionToPermanent' ||
      loanPurpose === 'Purchase' ||
      loanPurpose === 'Other'
    );
  }

  /**
   * addDays
   *
   * @param {string} orignalDate (yyyy-mm-dd)
   * @param {number} day eg: 1,2,3.. etc
   * @returns {string}  Date in yyyy/mm/dd format
   */
  addDays(orignalDate, days) {
    let cloneDate = new Date(orignalDate.valueOf());
    cloneDate.setDate(cloneDate.getDate() + days);

    let month = '' + (cloneDate.getMonth() + 1),
      day = '' + cloneDate.getDate(),
      year = cloneDate.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  }

  /**
   * findDay
   *
   * @param {string} orignalDate (yyyy-mm-dd)
   * @returns {number} index of particular day
   */
  findDay(orignalDate) {
    let date = orignalDate ? orignalDate.replace(/-/g, '/') : orignalDate;
    let cloneDate = new Date(date.valueOf());
    return cloneDate.getDay();
  }

  /**
   * buildUrl
   * @param {string} baseURL
   * @param {object} queryParams
   * @returns {string} url with query parameters
   * @example buildUrl('http://localhost:8080', {key1: 'value1', key2: 'value2'}) => 'http:// localhost:8080?key1=value1&key2=value2'
   *
   */
  buildUrl(baseURL, queryParams) {
    if (!queryParams || typeof queryParams !== 'object') return baseURL;

    const queryString = Object.keys(queryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');

    return baseURL + (queryString ? `?${queryString}` : '');
  }

  getVersionForAppLoader() {
    const ebsVersion = session.getItem('ngStorage-ebsVersion') || {};
    const encompassVersion = ebsVersion.encompassVersion;
    const match = encompassVersion.match(/(\d+).(\d+)/);
    return (match && match[0]) || '';
  }

  getDescriptionFromField(emField) {
    try {
      const hostService = get(window, 'hostApp.host.SSFObjectsUtilities', SSFObjectsUtilities);
      const ssfLoan = hostService.getInstance('loan');
      const paths = ssfLoan.fieldDefinitions();

      const fieldId =
        emField?.indexOf('loan') === -1 ? emField : emField.match(/\[([^\]]+)\]/)?.[1]?.replaceAll("'", '');
      if (fieldId) {
        const description = paths[fieldId]?.[3];
        if (description) {
          return description;
        }
      }
    } catch (e) {}
  }

  getJSONParsed = value => {
    try {
      return JSON.parse(value);
    } catch {
      return undefined;
    }
  };

  getHost = () => {
    return get(window, 'hostApp.host', undefined);
  };

  onPrintFromBlob = blob => {
    try {
      const windowURL = window.URL || window.webkitURL;
      const fileUrl = windowURL.createObjectURL(blob);

      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = fileUrl;

      iframe.onload = () => {
        const iframeWindow = iframe.contentWindow;

        const cleanup = () => {
          document.body.removeChild(iframe);
          window.removeEventListener('afterprint', cleanup);
          windowURL.revokeObjectURL(fileUrl);
        };

        window.addEventListener('afterprint', cleanup);

        iframeWindow.focus();
        iframeWindow.print();
      };

      document.body.appendChild(iframe);
      return true;
    } catch (error) {
      console.error('Error in onPrintFromBlob:', error);
      return false;
    }
  };
}

const utils = new Utils();

export { utils };
